export const messages = {
  data() {
    return {
      nameMsg: 'O campo nome é obrigatório.',
      emailMsg: 'O campo e-mail é obrigatório.',
      phoneMsg: 'O campo telefone é obrigatório.',
      passwordMsg: 'O campo senha é obrigatório.',

      invalidEmailMsg: 'E-mail inválido.',
      invalidPhoneMsg: 'Telefone inválido.',
      invalidPasswordMsg: 'A senha digitada não está de acordo com as regras acima.',
      invalidPasswordConfirmation: 'O campo confirmação de senha deve ser igual ao campo senha.',

      impossibleMsg: 'Não foi possível realizar a sua solicitação.',
      invalidTokenForgotPasswordMsg: 'Token de verificação inválido, por favor solicite uma nova troca de senha.',
      tokenForgotPasswordNotFoundMsg: 'Token de verificação não encontrado.',
    }
  },
  methods: {
    strCount(value) {
      return `Este campo deve conter no mínimo ${value} caracteres.`
    }
  }
}

export const _ = null
